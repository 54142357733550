<template>
  <div class="bg-background-quaternary">
    <div v-if="userStore.isLoading" class="flex h-screen w-screen items-center justify-center">
      <LoadingSpinner color="primary" />
    </div>

    <NetworkError v-else-if="userStore.hasNetworkError" class="h-screen w-screen" />

    <div v-else>
      <router-view />
      <HomePreviewModeFooter v-if="showHomeThemePreviewMenu" />
      <Button
        v-if="isUserLoggedIn && !showHomeThemePreviewMenu && !isDashboard"
        :id="OnboardingStepEnum.MODAL_ONBOARDING_HELP"
        icon-on-left-side="QuestionMark"
        class="fixed bottom-14 right-4 z-40 h-10 !min-h-[2.5rem] !w-10 items-center !py-0 !px-0 transition-all lg:right-10"
        :class="[
          {
            'bottom-28': isSearchPage,
          },
        ]"
        icon-class="!mr-0 !text-primary bg-white rounded-full p-1"
        @click="router.push('/help')"
      >
      </Button>
    </div>

    <DownloadWidget />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import Button from "./components/Button.vue";
import DownloadWidget from "./components/DownloadWidget.vue";
import LoadingSpinner from "./components/LoadingSpinner.vue";
import NetworkError from "./components/NetworkError.vue";
import HomePreviewModeFooter from "./components/platformSettings/HomePreviewModeFooter.vue";
import router from "./router";
import { useAuthStore } from "./store/auth";
import { OnboardingStepEnum } from "./store/onboardingMode";
import { usePlatformCompanyThemeStore } from "./store/platformCompanyTheme";
import { useUserStore } from "./store/user";

const userStore = useUserStore();

const isUserLoggedIn = computed(() => useAuthStore().isUserLoggedIn);
const isDashboard = computed(() => import.meta.env.VITE_PROJECT_TYPE === "admin");
const isSearchPage = computed(() => router.currentRoute.value.path === "/search");

const showHomeThemePreviewMenu = computed(
  () => usePlatformCompanyThemeStore().showHomeThemePreviewMenu
);
</script>
